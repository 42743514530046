import { ReactElement, ReactNode, useState, useContext, useEffect } from 'react'
import styles from './questions-page.module.css'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { getSymptoms, setSymptoms } from '../../stores/symptoms/actions';
import { userContext } from '../../App';
import { 
  getUser
} from '../../stores/user/actions'
import { 
  getSubject,
  updateSubject,
  setQuestionsReply
} from '../../stores/subject/actions'

type Props = {
  children?: ReactNode,
  getSymptoms:(token:string) => any,
  setSymptoms:(params:any) => any,
  getUser:(token:string) => any,
  getSubject(token:string, code:string):any,
  updateSubject(gender:string, height: string, weight: string):any
  setQuestionsReply(age:boolean, history:boolean):any
  store:any,
  className?: string
}

const QuestionsStep = (props: Props): ReactElement<Props> => {

  const { getSymptoms, setSymptoms, getUser, getSubject, updateSubject, setQuestionsReply, store } = props

  const userToken = useContext(userContext);

  const [checkedItems, setCheckedItems] = useState<any>({
    age: '',
    history: ''
  });

  const [errorsFields, setErrorsFields] = useState<any>({
    gender: false,
    age: false,
    history: false,
    height: false,
    weight: false
  });

  const [genderValue, setGenderValue]= useState('')
  const [height, setHeight] = useState('')
  const [weight, setWeight] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    if(store.userData["Access-Token"] === '') {
      getUser(userToken).then((res:any) => {
        getSubject(userToken, res.FirstName)
      })
    }
  }, [store])

  const isValidateFields = () => {
    let checkboxesIsError = true;
    let errObj = Object.assign({}, errorsFields);
    for (let prop in checkedItems) {
      if(checkedItems[prop] === '') {
        
        errObj[prop] = true
        checkboxesIsError = false
      }
    }

    const genderFieldValue = genderValue !== '' ? true : false;

    const question1 = height.length > 1 ? true : false;
    const question2 = weight.length > 1 ? true : false;
    errObj.height = !question1;
    errObj.weight = !question2;
    errObj.gender = !genderFieldValue;

    setErrorsFields(errObj)

    return checkboxesIsError && question1 && question2 && genderFieldValue
  }

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();
    if (isValidateFields()) {
      
      const heightValue = height;
      const weightValue = weight;

      const age = checkedItems.age === 'yes' ? true : false;
      const history = checkedItems.history === 'yes' ? true : false;

      updateSubject(checkedItems.gender,heightValue,weightValue).then(() => setQuestionsReply(age, history)).then(() => navigate('/jap-symptom-page'))
    }
  }

  const handleChange = (event:any) => {
    let errObj = Object.assign({}, errorsFields);
    errObj[event.target.name] = false
    setCheckedItems({...checkedItems, [event.target.name] : event.target.value });
    setErrorsFields(errObj)
}

const onChangeGenderHandler = (event:any) => {
  let errObj = Object.assign({}, errorsFields);
  errObj.gender = false
  setGenderValue(event.target.value)
  setErrorsFields(errObj)
}

const onChangeHandler = (event: React.ChangeEvent<{ value: string}>, questionNumber: number):void => { 
  if(event.target.value.length > 3) {
    return
  }
  let errObj = Object.assign({}, errorsFields);

  if(questionNumber === 1) {
    errObj.height = false;
    setHeight(event.target.value)
  }
  if(questionNumber === 2) {
    errObj.weight = false;
    setWeight(event.target.value)
  }
  setErrorsFields(errObj)
}


  return (
      <div className={styles.container}>
        <div className={styles.form_wrap}>
        <div className={`${styles.header} ${styles.steps}`}>
            <div className={styles.header_line}>
              Screen 2 of 4
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.header_line}>
              <div className={styles.translate}>皆様へのアンケートです。 あてはまるものをお選びください。</div>
            This is a questionnaire for participants. 
  Please select your answer. 
            </div>
          </div>
          <form onSubmit={submitHandler} method="get">
            <div className={styles.header}>
              <div className={styles.header_line}>
                <span className={styles.translate}>性別</span> Gender
              </div>
              <div className={styles.radio_container}>
                <div className={styles.radio_line}>
                  <label htmlFor={`gender-v1`}>
                    <div className={styles.radio_wrap}>
                      <input 
                        className={styles.radio} 
                        type="radio" 
                        name={`gender`}
                        id="gender-v1"
                        checked={genderValue === 'Male' ? true : false}
                        value="Male" 
                        onChange={onChangeGenderHandler} 
                        required
                        />
                        <span className={styles.checkmark}></span>
                      </div>
                      <div className={styles.radio_text}>
                        <div className={styles.translate}>
                          男性 
                        </div>
                        <div>&nbsp;Male</div>
                      </div>
                  </label>
                </div>
                <div className={styles.radio_line}>
                  <label htmlFor={`gender-v2`}>
                    <div className={styles.radio_wrap}>
                      <input 
                        className={styles.radio} 
                        type="radio" 
                        name={`gender`}
                        id="gender-v2"
                        onChange={onChangeGenderHandler} 
                        value="Female"
                        checked={genderValue === 'Female' ? true : false}
                        required
                        />
                        <span className={styles.checkmark}></span>
                      </div>
                      <div className={styles.radio_text}>
                        <div className={styles.translate}>
                        女性
                        </div>
                        <div>&nbsp;Female</div>
                      </div>
                  </label>
                </div>
              </div>
            {errorsFields.gender &&
              <div className={styles.err_msg}>
                This field is required, please enter a value!
              </div>
            }
            </div>
            <div className={styles.header}>
              <div className={styles.header_line}>
                <span className={styles.translate}>身長</span> Height (cm)
              </div>
              <input 
                type="number" 
                className="input" 
                maxLength={3}
                name="height" 
                value={height} 
                onChange={event => onChangeHandler(event,1)}
                required 
              />
            {errorsFields.height &&
              <div className={styles.err_msg}>
                This field is required, please enter a value!
              </div>
            }
            </div>
            <div className={styles.header}>
              <div className={styles.header_line}>
                <span className={styles.translate}>体重</span> Weight (kg)
              </div>
              <input 
                type="number" 
                className="input" 
                maxLength={3}
                name="weight" 
                value={weight} 
                onChange={event => onChangeHandler(event,2)}
                required 
              />
            {errorsFields.weight &&
              <div className={styles.err_msg}>
                This field is required, please enter a value!
              </div>
            }
            </div>
            <div className={styles.header}>
              <div className={styles.header_line}>
                <div className={styles.translate}>あなたは18歳以上ですか？</div>
                  Are you 18 years of age or older?
              </div>
              <div className={styles.radio_container}>
                <div className={styles.radio_line}>
                  <label htmlFor={`age-v1`}>
                    <div className={styles.radio_wrap}>
                      <input 
                        className={styles.radio} 
                        type="radio" 
                        name={`age`}
                        id="age-v1"
                        value="yes"
                        checked={checkedItems.age === 'yes' ? true : false} 
                        onChange={handleChange} 
                        />
                      <span className={styles.checkmark}></span>
                      </div>
                      <div className={styles.radio_text}>
                        <div className={styles.translate}>
                          はい 
                        </div>
                        <div>&nbsp;Yes</div>
                      </div>
                  </label>
                </div>
                <div className={styles.radio_line}>
                  <label htmlFor={`age-v2`}>
                    <div className={styles.radio_wrap}>
                      <input 
                        className={styles.radio} 
                        type="radio"
                        id="age-v2" 
                        name={`age`}
                        value="no"
                        checked={checkedItems.age === 'no' ? true : false} 
                        onChange={handleChange} 
                        />
                        <span className={styles.checkmark}></span>
                      </div>
                      <div className={styles.radio_text}>
                        <div className={styles.translate}>
                        いいえ
                        </div>
                        <div>&nbsp;No</div>
                      </div>
                  </label>
                </div>
              </div>
            {errorsFields.age &&
              <div className={styles.err_msg}>
                This field is required, please enter a value!
              </div>
            }
            </div>
            <div className={styles.header}>
              <div className={styles.header_line}>
                <div className={styles.translate}>声帯に関わる手術や癌治療を受けたことはありますか？</div>
                Do you have a history of surgery or cancer therapy that involves my vocal cords?
              </div>
              <div className={styles.radio_container}>
                <div className={styles.radio_line}>
                  <label htmlFor={`history-v1`}>
                    <div className={styles.radio_wrap}>
                      <input 
                        className={styles.radio} 
                        type="radio" 
                        name={`history`}
                        id="history-v1"
                        value="yes"
                        checked={checkedItems.history === 'yes' ? true : false} 
                        onChange={handleChange} 
                        />
                        <span className={styles.checkmark}></span>
                      </div>
                      <div className={styles.radio_text}>
                        <div className={styles.translate}>
                          はい 
                        </div>
                        <div>&nbsp;Yes</div>
                      </div>
                  </label>
                </div>
                <div className={styles.radio_line}>
                  <label htmlFor={`history-v2`}>
                    <div className={styles.radio_wrap}>
                      <input 
                        className={styles.radio} 
                        type="radio" 
                        name={`history`}
                        id="history-v2"
                        value="no"
                        checked={checkedItems.history === 'no' ? true : false} 
                        onChange={handleChange} 
                        />
                        <span className={styles.checkmark}></span>
                      </div>
                      <div className={styles.radio_text}>
                        <div className={styles.translate}>
                        いいえ
                        </div>
                        <div>&nbsp;No</div>
                      </div>
                  </label>
                </div>
              </div>
            {errorsFields.history &&
              <div className={styles.err_msg}>
                This field is required, please enter a value!
              </div>
            }
            </div>
          </form>
          <button
            type={'submit'}
            className={styles.submit}
            onClick={submitHandler}
          >
            <span className={styles.button_text}>送信</span>
            <span className={styles.button_text}>Submit</span>
          </button>
        </div>
      </div>
  )
}


const mapStateToProps = (state:any) => {
  return {store: state}
}
const mapDispatchToProps = {
  getSymptoms, 
  setSymptoms,
  getUser,
  getSubject,
  updateSubject,
  setQuestionsReply
}

export const QuestionsPage = connect(
  mapStateToProps, mapDispatchToProps
)(QuestionsStep)
