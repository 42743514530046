import { ReactElement, ReactNode, useState, useEffect, useRef, useContext } from 'react'
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { 
  uploadFileForSubject
} from '../../stores/subject/actions'
import { connect } from 'react-redux'
import styles from './recording-page.module.css'
import microphoneImage from './../../assets/microphone.png';
import { useNavigate } from "react-router-dom";
import { userContext } from './../../App';
import { 
  getUser
} from '../../stores/user/actions'
import { 
  getSubject
} from '../../stores/subject/actions'

type Props = {
  children?: ReactNode,
  type?: string,
  uploadFileForSubject(file:any):any,
  className?: string,
  getUser:(token:string) => any,
  getSubject(token:string, code:string):any,
  store:any,
}

const RecordingStep = (props: Props): ReactElement<Props> => {

  const { uploadFileForSubject, getUser, getSubject, store } = props
  const [countRecords, setCountRecords] = useState(0)
  const [activeBtn, setActiveBtn] = useState(true)

  const [stream, setStream] = useState<any>(null);
  const [blob, setBlob] = useState<any>(null);
  const refAudio = useRef<any>(null);
  const recorderRef = useRef<any>(null);

  const [showMicPermisionErr, setShowMicPermisionErr] = useState(false);
  const [ seconds, setSeconds ] = useState(30);
  const [ timerActive, setTimerActive ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const navigate = useNavigate();

  const userToken = useContext(userContext);

  useEffect(() => {
    if(store.userData["Access-Token"] === '') {
      getUser(userToken).then((res:any) => {
        getSubject(userToken, res.FirstName)
      })
    }
  }, [store])

  useEffect(() => {
    if (seconds > 0 && timerActive) {
      setTimeout(setSeconds, 1000, seconds - 1);
    } else {
      setTimerActive(false);
      handleStop();
    }
  }, [seconds,timerActive]);

  const handleRecording = async () => {
    setShowMicPermisionErr(false)
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
      setTimerActive(true)
      setStream(mediaStream);
      recorderRef.current = new RecordRTC(mediaStream, { type: 'audio', mimeType: 'audio/wav', recorderType: StereoAudioRecorder });
      recorderRef.current.startRecording();
    } catch(e:any) {
      setShowMicPermisionErr(true)
      setTimerActive(false)
      setSeconds(30);
      console.log(e.message);
    }
  };

  const handleStop = () => {
    if(seconds !== 0) {
      return
    }
    recorderRef.current.stopRecording(() => {
      setBlob(recorderRef.current.getBlob());
    });
  };


  useEffect(() => {
    if (!refAudio.current) {
      return;
    }

    // refAudio.current.srcObject = stream;
  }, [stream, refAudio]);

  const getFileName = (fileExtension:string) => {
      var d = new Date();
      var year = d.getUTCFullYear();
      var month = d.getUTCMonth();
      var date = d.getUTCDate();
      return 'Record-' + year + month + date + '.' + fileExtension;
  }

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();

    if(seconds !== 0) {
      return
    }

    setActiveBtn(false)
    setLoading(true)

    const fileName = getFileName('wav');
    const fileObject = new File([blob], fileName, {
        type: 'audio/wav'
    });

    console.log('fileObject', fileObject)

    uploadFileForSubject(fileObject).then(() => {
      setCountRecords(countRecords + 1)
      setBlob(null)
      setSeconds(30)
      setActiveBtn(true)
      setLoading(false)
      if (countRecords === 1) {
        navigate('/jap-recording-thank-you-page')
      }
    })
  }

  return (
    <div className={styles.container}>
      <div className={styles.form_wrap}>
          <div className={`${styles.header} ${styles.steps}`}>
            <div className={styles.header_line}>
              Screen 4 of 4
            </div>
          </div>
        <div className={styles.header}>
          <div className={styles.header_line}>
          <div className={styles.translate}>下記ガイドに沿って、お客様の音声を録音してください。1回あたり30秒ずつで、2回行います。</div>
          Please record your voice followed the instruction. 
each recording is 30 sec and need to record twice. 
          </div>
        </div>
        <form onSubmit={submitHandler} method="get">
          <label htmlFor="field">
              <ul className={styles.list}>
                <li>
                  <div className={styles.translate}>1. 録音する準備ができたら、画面下にあるマイクのアイコンを押してください。
<a href="japan.study.sonaphi.com">‘japan.study.sonaphi.com’</a>がマイクへのアクセスを求める画面が表示されましたら、「許可」を押してください。</div>
Please press the MIC button below when ready to record.
Also, approve when the page which <a href="japan.study.sonaphi.com">‘japan.study.sonaphi.com’</a> ask you access your MIC came out.
                </li>
                <li>
                    <div className={styles.translate}>2. ノイズの少ない静かな部屋での録音をお願いします。普段の会話する時の声で、長い沈黙がないように話しかけてください。
                      <p className={styles.gray_color}>
                      例）今日の天気、今日の朝ご飯、今日の気分、体調、今日どんな1日を過ごしたか、休日の過ごし方、思い出に残る旅など。お好きな話題で大丈夫です。
                      </p>
                    </div>
                    The recording is best done in a quiet room with minimal background noise. Talk in normal conversational voice and tone without long pauses here are some topic ideas: today’s weather, how are you feeling today, your body condition, how was your day, how you spend the holiday, what’s your memorable trip etc.
                </li>
                <li>
                  <div className={styles.translate}>3. 30秒の録音が終わったら、必ず送信(submit)ボタンをクリックしてください。</div>
                  When 30 seconds has elapsed you will be able to submit your recording to the study.
                </li>
              </ul>
          </label>
        </form>
        {countRecords === 0 && <div className={`${styles.header} ${styles.repeat_msg}`}>
          <div className={styles.header_line}>
          <div className={styles.translate}>下記のマイクのアイコンをクリックし、
1回目の録音をしてください。
</div>
Please click MIC icon and make the 1st recording!
          </div>
        </div>
        }
        {countRecords === 1 && <div className={`${styles.header} ${styles.repeat_msg}`}>
          <div className={styles.header_line}>
          <div className={styles.translate}>下記のマイクのアイコンをクリックし、
2回目の録音をしてください。</div>
Please click MIC icon and make another recording!
          </div>
        </div>
        }
        {showMicPermisionErr && <div className={styles.err_msg}>
          Microphone permission denied.
          Please turn on access to the microphone.
        </div>}
        <div className={styles.btn_wrap}>
          <button className={styles.start_btn} onClick={handleRecording}>
            <img src={microphoneImage} alt="microphone" />
            start
          </button>
        </div>
        { seconds !== 0 ?
          <div className={styles.timer_wrap}>
              00:{String(seconds).length === 1 ? '0' + String(seconds) : String(seconds)}
          </div>
          : ''
        }
      {blob && (
            <div className={styles.submit_btn_wrap}>
              <button
                type={'submit'}
                className={`${styles.submit} ${loading ? styles.button__loading : ''}`}
                onClick={submitHandler}
                disabled={!activeBtn}
              >
                <span className={styles.button_text}>送信</span>
                <span className={styles.button_text}>Submit</span>
              </button>
            </div>
          )}
      </div>
    </div>
  )
}

const mapStateToProps = (state:any) => {
  return {store: state}
}
const mapDispatchToProps = {
  uploadFileForSubject,
  getUser,
  getSubject
}

export const RecordingPage = connect(
  mapStateToProps, mapDispatchToProps
)(RecordingStep)
