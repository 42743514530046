import ApiService from '../../lib/api'
import { Profile } from './interfaces'

import { 
  LOGIN_USER,
  CREATE_USER,
  GET_USER,
  UPDATE_USER
} from './types'

export const loginUser = (code:string) => (dispatch:any) => {
  return ApiService.login(code)
    .then((response:Profile) => {

      dispatch({
        type: LOGIN_USER, 
        payload: {
          userData: response
        }
      })

      return response
    })
}

export const createUser = (code:string) => (dispatch:any) => {
  return ApiService.create(code)
    .then((response:Profile) => {

      if(typeof response != 'string') {
        dispatch({
          type: CREATE_USER, 
          payload: {
            userData: response
          }
        })
      }

      return response
    })
}

export const getUser = (token:string) => (dispatch:any) => {
  return ApiService.getUser(token)
    .then((response:Profile) => {

      dispatch({
        type: GET_USER, 
        payload: {
          userData: response
        }
      })

      return response
    })
}

export const updateUser = (token:string, gender:string, height:string, weight:string) => (dispatch:any) => {
  return ApiService.updateUser(token,gender,height,weight)
    .then((response:Profile) => {

      dispatch({
        type: UPDATE_USER, 
        payload: {
          userData: response
        }
      })

      return response
    })
}



