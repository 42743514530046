import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import StudyIdPage from './components/study-id-page';
import QuestionsPage from './components/questions-page';
import RecordingPage from './components/recording-page';
import SymptomPage from './components/symptom-page';
import ThankYouPage from './components/thank-you-page';
import { Provider } from 'react-redux'
import store from './stores'
import styles from './App.module.css'
import Cookies from 'universal-cookie';

export const userContext = React.createContext<any | undefined>(undefined);

function App() {
  const cookies = new Cookies();
  const token = cookies.get('adminToken')

  return (
    <div className="App">
      <Provider store={store}>
        <userContext.Provider value={token}>
          <div className={styles.navbar}>
            <div className={styles.navigation}>
              <a href="/" className={styles.brand} aria-label="home">
                <img src="https://uploads-ssl.webflow.com/5f739438e96837b10c12bc23/5f984360aa42540b43d34524_Sonaphi%20grey-small.png" alt=""/>
              </a>
              <nav role="navigation" className={styles.nav_menu}>
                <a href="https://www.sonaphi.com/faqs" target="_blank" rel="noreferrer" className={styles.nav_link}>FAQ</a>
                <a href="https://www.sonaphi.com/contact" className={styles.nav_link}>Contact</a>
              </nav>
            </div>
          </div>
          <BrowserRouter>
            <Routes>
              <Route path="/jap-study-id-page" element={
              <StudyIdPage />
              } />
              <Route path="/jap-questions" element={<QuestionsPage />} />
              <Route path="/jap-symptom-page" element={<SymptomPage />} />
              <Route path="/jap-recording-1" element={<RecordingPage />} />
              <Route path="/jap-recording-thank-you-page" element={<ThankYouPage />} />
              <Route path="/" element={
                <StudyIdPage />
              } />
            </Routes>
          </BrowserRouter>
        </userContext.Provider>
      </Provider>
    </div>
  );
}

export default App;
