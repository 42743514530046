import { ReactElement, ReactNode, useState, useContext, useEffect } from 'react'
import styles from './symptom-page.module.css'
import { connect } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { getSymptoms, setSymptoms } from '../../stores/symptoms/actions';
import { userContext } from './../../App';
import { 
  getUser
} from '../../stores/user/actions'
import { 
  getSubject
} from '../../stores/subject/actions'

type Props = {
  children?: ReactNode,
  getSymptoms:(token:string) => any,
  setSymptoms:(params:any) => any,
  getUser:(token:string) => any,
  getSubject(token:string, code:string):any,
  store:any,
  className?: string
}

const SymptomStep = (props: Props): ReactElement<Props> => {

  const { getSymptoms, setSymptoms, getUser, getSubject, store } = props

  const userToken = useContext(userContext);

  const [symtomsArr, setSymtomsArr] = useState([])
  const [checkedItems, setCheckedItems] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    if(store.userData["Access-Token"] === '') {
      getUser(userToken).then((res:any) => {
        getSubject(userToken, res.FirstName)
      })
    }
  }, [store])

  const symtomsDictionary:any = {
    'Select all that apply.': 'あてはまるもの全てを選んでください', 
    'Back Pain':'腰痛',
    'Bluish lips or face': '紫色の唇や顔',
    'Stuffy Nose': '鼻づまり',
    'Cough (dry)': '乾いた咳',
    'Cough (wet)': '湿った咳',
    'Fatigue': '疲労感',
    'Fever': '熱',
    'Headache':'頭痛',
    'Inability to wake or stay awake': '眠気',
    'Joint Aches': '関節痛',
    'Loss of Taste': '食欲不振',
    'Loss of Smell': '臭いがしない',
    'Mucus or phlegm': '痰',
    'Muscle Pain': '筋肉痛',
    'Persistent pain or pressure in the chest': '胸の圧迫感、痛み',
    'Runny Nose': '鼻水',
    'Shortness of breath': '息切れ',
    'Vomiting': '吐き気',
    'None of the above': 'どれも該当なし' 
}

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();

    const repliesArr = Object.keys(checkedItems).map((item:any) => {
      return {
        "symptom_text": item,
        "reply": true
      }
    })

    setSymptoms(repliesArr).then(() => navigate('/jap-recording-1'))
  }

  const handleChange = (event:any) => {
    // updating an object instead of a Map
    setCheckedItems({...checkedItems, [event.target.name] : event.target.checked });
}

  const getSymtomsList = ():ReactNode => {
    
    if(symtomsArr.length === 0 && store.userData["Access-Token"] && store.userData["Access-Token"] !== '') {
       getSymptoms(store.userData["Access-Token"]).then((res:any) => {
        const filterArr = res.filter((item:any) => symtomsDictionary.hasOwnProperty(item.symptom_text));
        setSymtomsArr(filterArr)
        return renderSymtomsControls(filterArr)
      })
    } else {
      const arr = symtomsArr
      return renderSymtomsControls(arr)
    }
  }

  const renderSymtomsControls = (symtomsArr:Array<any>) => {

    return symtomsArr.map((item, index:number) => {
      return (
        <div className={styles.checkbox_line} key={'fragment' + index}>
          <label htmlFor={`symtoms${item.id}`}>
            <div className={styles.checkbox_wrap}>
              <input 
                className={styles.checkbox} 
                type="checkbox" 
                id={`symtoms${item.id}`} 
                name={`${item.symptom_text}`}
                checked={checkedItems[item.name]} 
                onChange={handleChange} 
                />
              </div>
              <div className={styles.symptom_text}>
                { 
                symtomsDictionary[item.symptom_text] ? 
                <div className={styles.translate}>
                  {`${symtomsDictionary[item.symptom_text]}`}&nbsp;
                </div>
                : ''}
                <div>{`(${item.symptom_text})`}</div>
              </div>
          </label>
        </div>
      )
    })
  }

  return (
      <div className={styles.container}>
        <div className={styles.form_wrap}>
        <div className={`${styles.header} ${styles.steps}`}>
            <div className={styles.header_line}>
              Screen 3 of 4
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.header_line}>
              <div className={styles.translate}>過去24時間に何か症状はありましたか？
あてはまるものをすべてお選びください
</div>
            Check all symptoms that you are experiencing in the last 24 hours.
            </div>
          </div>
          <form onSubmit={submitHandler} method="get">
            <div className={styles.checkbox_container}>
              {getSymtomsList()}
            </div>
          </form>
          <button
            type={'submit'}
            className={styles.submit}
            onClick={submitHandler}
          >
            <span className={styles.button_text}>送信</span>
            <span className={styles.button_text}>Submit</span>
          </button>
        </div>
      </div>
  )
}


const mapStateToProps = (state:any) => {
  return {store: state}
}
const mapDispatchToProps = {
  getSymptoms, 
  setSymptoms,
  getUser,
  getSubject
}

export const SymptomPage = connect(
  mapStateToProps, mapDispatchToProps
)(SymptomStep)
