import { ReactElement, ReactNode, useState } from 'react'
import { connect } from 'react-redux'
import { 
  createUser,
  loginUser
} from '../../stores/user/actions'
import { 
  createSubject,
  getSubject
} from '../../stores/subject/actions'
import styles from './study-id-page.module.css'
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';

type Props = {
  children?: ReactNode,
  createUser(code:string):any,
  loginUser(code:string):any,
  createSubject(token:string, code:string):any,
  getSubject(token:string, code:string):any,
  store:any,
  className?: string
}

const StudyIdStep = (props: Props): ReactElement<Props> => {

  const { createUser, createSubject, loginUser, getSubject } = props
  const [activeBtn, setActiveBtn] = useState(true)
  const [showValidateErrMsg, setShowValidateErrMsg] = useState(false)
  const [code, setCode] = useState('')
  const navigate = useNavigate();
  const cookies = new Cookies()

  const onChangeHandler = (event: React.ChangeEvent<{ value: string}>):void => { 
    setShowValidateErrMsg(false)
    if(event.target.value.length > 3) {
      return
    }
    setCode(event.target.value)
  }

  const onBlurHandler = (
    event: React.FocusEvent<HTMLInputElement>
  ) => { 
    if(code.length !== 3) { 
      setShowValidateErrMsg(true)
    }
  }

  const submitHandler = (event: React.SyntheticEvent):void => { 
    event.preventDefault();
    setActiveBtn(false)

    if(code.length !== 3) {
      setShowValidateErrMsg(true)
      return
    }

    createUser(code).then((res:any) => {
      setActiveBtn(true)
      if(res === 'Email is already taken' ) { 
        return loginUser(code).then((res:any) => {

          if(res["Access-Token"] && res["Access-Token"].length > 1) {
            const token = res["Access-Token"]
            getSubject(token, code).then(() => {
              cookies.set('adminToken', token, { path: '/' })
              navigate('/jap-questions')
            })
          }
        })
      }
      if(res["Access-Token"] && res["Access-Token"].length > 1) {
        const token = res["Access-Token"]
        createSubject(token, code).then((res:any) => {
          cookies.set('adminToken', token, { path: '/' })
          navigate('/jap-questions')
        })
      }
    }).catch(() => setActiveBtn(true))
  }

  return (
    <div className={styles.container}>
      <div className={styles.form_wrap}>
        <div className={`${styles.header} ${styles.steps}`}>
          <div className={styles.header_line}>
            Screen 1 of 4
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.header_line}>
            <div className={styles.translate}>「音声によるコロナ判定」の研究にご参加いただき、ありがとうございます。</div>
            Thank you for participating in the research on VRI.
          </div>
        </div>
        <div className={styles.header}>
          <div className={styles.header_line}>
            <div className={styles.translate}><span className={styles.red_color}>必ず、スマートフォンを用いてご参加ください。</span>PCではご参加いただけませんので、ご了承ください。</div>
            Please be sure to use your smartphone to participate in this test. Cannot be used on a PC. 
          </div>
        </div>
        <form onSubmit={submitHandler} method="get">
          <label htmlFor="field">
            <div className={styles.translate}>被験者ID（3桁）をご入力ください</div>
            Enter your user ID (3digits).
          </label>
          <input 
            type="number" 
            className="input" 
            maxLength={3}
            name="field" 
            placeholder="Digit number"
            value={code} 
            onChange={event => onChangeHandler(event)}
            onBlur={event => onBlurHandler(event)}
            required 
          />
          {showValidateErrMsg &&
          <div className={styles.err_msg}>
            <div className={styles.translate}>有効なコードではありません。3桁のお客様IDをご入力ください。</div>
            The code should contain 3 digits, please, enter a valid code!
          </div>}
        </form>
        <button
          type={'submit'}
          className={styles.submit}
          onClick={submitHandler}
          disabled={!activeBtn}
        >
            <span className={styles.button_text}>送信</span>
            <span className={styles.button_text}>Submit</span>
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state:any) => {
  return {store: state}
}
const mapDispatchToProps = {
  createUser,
  loginUser,
  createSubject,
  getSubject
}
export const StudyIdPage = connect(
  mapStateToProps, mapDispatchToProps
)(StudyIdStep)
