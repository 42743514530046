import { ReactElement, ReactNode } from 'react'
import styles from './thank-you-page.module.css'

type Props = {
  children?: ReactNode,
  className?: string
}

const ThankYouPage = (props: Props): ReactElement<Props, any> => {

  return (
    <div className={styles.container}>
      <div className={styles.form_wrap}>
        <div className={styles.header}>
          <div className={styles.header_line}>Thank you!</div>
        </div>
        <p>Click <a href="https://www.sonaphi.com/contact">here</a> to email Al Borg Laboratory if you have any questions.</p>
      </div>
    </div>
  )
}

export default ThankYouPage